import React from "react";

const TokenInformation = ({ selectedProject }) => {
    return <div className="ai-card-info token">
        <div className="ai-card-info-title">Token information</div>
        <div className="ai-card-info-inner">
            <div className="table-responsive">
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td>Name</td>
                            <td className="text-end">
                                <h5 className="mb-0">{selectedProject.name}</h5>
                            </td>
                        </tr>
                        <tr>
                            <td>Token Symbol</td>
                            <td className="text-end">
                                <h5 className="mb-0">{selectedProject.projectTokenSymbol}</h5>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export default TokenInformation