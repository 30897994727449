import React from "react";
import "./index.scss";

const AiButtonKYC = ({ disabled = false, status = "", icon, onClick }) => {
    return <button type='button' disabled={disabled} className={`ai-kyc ms-2 ${status}`} onClick={onClick}>
        <span className="decor"></span>
        {icon ? icon : <></>}
        KYC
    </button>
}
export default AiButtonKYC