import React, { useState } from "react";





const LeaderBoardButton = ({text}) => {

   const [btnText, setBtnText] = useState(text);

    // const onMouseover = (e) => {
    //     setBtnText(afterTxt)
    // }
    // const onMouseLeave = (e) => {
    //     setBtnText(beforeTxt)
    // }
    return <span className='ai-btn sm w-auto px-3 mx-3 warning' style={{ cursor: 'text', minWidth:120 }}  
        // onMouseEnter ={()=>onMouseover()} 
        // onMouseLeave ={()=>onMouseLeave()} 
    >{btnText}</span>
}
export default LeaderBoardButton