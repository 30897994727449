import moment from "moment";
import React from "react";

const PoolInformation = ({ selectedProject }) => {
    return <div className="ai-card-info pool">
        <div className="ai-card-info-title">Pool information</div>
        <div className="ai-card-info-inner">
            <div className="table-responsive">
                <table className="table mb-0">
                    <tbody>
                        <tr>
                            <td>Opens</td>
                            {
                                selectedProject.openTime === undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.openTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <td>FCFS Opens</td>
                            {
                                selectedProject.fcfsOpenTime === undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.fcfsOpenTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <td>Closes</td>
                            {
                                selectedProject.closeTime === undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0">{moment(selectedProject.closeTime * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <td>Swap Rate</td>
                            {
                                selectedProject.openTime !== undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0" id="idBusdConvert">
                                            <span className="text-nowrap">1 {selectedProject.symbol}</span>
                                            <span className="text-nowrap"> = {selectedProject['rate'].toFixed(4)} {selectedProject.projectTokenSymbol}</span>
                                        </h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0" id="idBusdConvert">TBA</h5>
                                    </td>
                            }
                        </tr>

                        <tr>
                            <td>Cap</td>
                            {
                                selectedProject.openTime !== undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0">{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0">TBA</h5>
                                    </td>
                            }
                        </tr>
                        <tr>
                            <td>Total Users Participated</td>
                            <td className="text-end">
                                <h5 className="mb-0">{selectedProject.totalCountUserParticipated || 0}</h5>
                            </td>
                        </tr>
                        <tr>
                            <td>Total Funds Swapped</td>
                            {
                                selectedProject.openTime !== undefined ?
                                    <td className="text-end">
                                        <h5 className="mb-0 text-nowrap">{selectedProject.totalFundParticipated.toFixed(4) || 0} {selectedProject.symbol || ""}</h5>
                                    </td>
                                    : <td className="text-end">
                                        <h5 className="mb-0">0</h5>
                                    </td>
                            }

                        </tr>
                        <tr>
                            <td>Access Type</td>
                            <td className="text-end">
                                <h5 className="mb-0">{selectedProject.isPrivate ? "Private" : "Public"}</h5>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
}
export default PoolInformation