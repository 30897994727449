import React, { useState, } from "react";
import { ACTION_CONST } from "../../../constants";
import { useDispatch } from "react-redux";
import { helpers } from "../../../shared/utils/helpers";
import { useProjectName, useProjectSymbol, useSelectedProject, useWeb3Utils } from "../../../hook/useState";
import { toast } from 'react-toastify';
import AiButton from "../../../shared/components/Button";
import { Modal } from "react-bootstrap";

const InvestmentModal = (props) => {
  const dispatch = useDispatch();
  const selectedProject = useSelectedProject();
  const web3Utils = useWeb3Utils();
  const [amount, setAmount] = useState(0);
  const projectName = useProjectName();
  const symbol = useProjectSymbol();
  const [enableBuyBtn, setEnableBuyBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleClickMax = () => {
    if (Number(props.allocationNumber) === 0 || Number(props.remainingAllocation === 0)) {
      setEnableBuyBtn(false);
      return;
    }
    if (props.allocationNumber > 0 && props.allocationNumber < props.remainingAllocation && props.allocationNumber < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.allocationNumber, 6);
      setAmount(helpers.formatNumberDownRound(props.allocationNumber, 6));
      setEnableBuyBtn(true);
      return;
    }
    if (props.remainingAllocation > 0 && props.remainingAllocation < props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.remainingAllocation, 6);
      setAmount(helpers.formatNumberDownRound(props.remainingAllocation, 6));
      setEnableBuyBtn(true);
      return;
    }
    if (props.walletInfo.tokenBalance > 0 && props.remainingAllocation >= props.walletInfo.tokenBalance) {
      document.getElementById("inputAmountSwap").value = helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6);
      setAmount(helpers.formatNumberDownRound(props.walletInfo.tokenBalance, 6))
      setEnableBuyBtn(true);
      return;
    }
    setEnableBuyBtn(false);
  }

  const handleOnchangeAmount = (e) => {
    const amountInputValue = Number(e.target.value);
    // check balance of account
    if (0 < amountInputValue && amountInputValue <= props.remainingAllocation
      && amountInputValue <= props.walletInfo.remainingAllocation
      && amountInputValue <= props.walletInfo.tokenBalance) {
      setEnableBuyBtn(true);
    } else {
      setEnableBuyBtn(false)
    }
    setAmount(amountInputValue)
    props.handleInputAmount(amountInputValue)
    return;
  }

  const handleBuyClick = () => {
    if (web3Utils && selectedProject) {
      dispatch({
        type: ACTION_CONST.REQUEST_SUBMIT
      })
      web3Utils.buyTokens({
        contractAddress: selectedProject.contract,
        tokenAddress: selectedProject.tokenAddress,
        amount: Number(amount)
      }, (data) => {
        if (data.status === "BUY_SUCCESS") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          props.handleBuyClick()
          setEnableBuyBtn(false);
          document.getElementById("inputAmountSwap").value = 0;
          toast.success("Successfully Joined Pool!");
          setShowModal(false);
        }
        if (data.status === "BUY_FAIL") {
          dispatch({ type: ACTION_CONST.REQUEST_DONE })
          toast.error("Failed to Join Pool");
        }
      }).catch(err => {
        dispatch({ type: ACTION_CONST.REQUEST_DONE })
        toast.error("Failed to Join Pool! Please try again!");
        console.log(err);
      })
    }
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }

  return (
    <>
      <AiButton disabled={props.disabled} size="ms-0" buttonText='Join Pool' onClick={() => setShowModal(true)} />
      <Modal size='md' className='ai-modal' centered show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Join {projectName} Pool</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <div className="form-label">{symbol} Amount</div>
            <div className="ai-amout-input">
              <input type="number" className="form-control form-control-lg" placeholder={0.0} defaultValue={0} autoFocus onChange={(e) => handleOnchangeAmount(e)} id="inputAmountSwap" />
              <span style={{ cursor: 'pointer' }} onClick={handleClickMax}>MAX</span>
            </div>
          </div>
          <div className="text-end">Your balance: <b className="text-white">{props?.tokenBalance} {props?.symbol}</b></div>
          <div className="mt-4">
            <AiButton disabled={!enableBuyBtn} onClick={() => handleBuyClick()} buttonText="Join" size="w-100" variant="warning" />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InvestmentModal;
