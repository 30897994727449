import React from "react";
import "./index.scss";

const AiSocials = ()=>{
    return <div className="ai-socials">
        <a href="https://discord.gg/aipad" target="_blank" rel="noopener noreferrer"><i className="fab fa-discord"></i></a>
        <a href="https://twitter.com/aipadtech" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
        <a href="https://t.me/AIPADTECH" target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram-plane"></i></a>
        <a href="https://www.tiktok.com/@a.i.pad" target="_blank" rel="noopener noreferrer"><i className="fab fa-tiktok"></i></a>
    </div>
}
export default AiSocials;