import { get } from "lodash";
import React from 'react';
import BlockUi from "react-block-ui";
import { useSelector } from 'react-redux';
import AiFooter from "../components/Footer";
import AiHeader from "../components/Header";
import AiSocials from "../components/Socials";

export const PrivateLayout = ({ children, ...rest }) => {
    const showBlockUI = useSelector((state) =>
        get(state, "utils.blocking", false)
    );

    return <BlockUi tag="div" blocking={showBlockUI}>
        <AiHeader />
        <main>{children}</main>
        <AiFooter />
        <AiSocials />
    </BlockUi>;
};