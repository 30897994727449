import React from "react";
import { Container } from "react-bootstrap";

const TermOfUsePage = () => {
    return <div className="ai-page">
        <Container>
            <h1 className="ai-page-title glitch" data-text="Term Of Use">Term Of Use</h1>
            <div className="ai-page-inner">
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Et iusto quibusdam nam minus, corrupti laborum eum enim laudantium! Hic dignissimos doloremque nulla iste cumque omnis! Ipsa, debitis. Distinctio, repellendus maiores.</p>
            </div>
        </Container>
    </div>
}
export default TermOfUsePage;