import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useCloseProjects } from "../../../hook/useState";
import AiSection from "../../../shared/components/Section";

const ClosedProjectsComponent = () => {
  const [tempLoading, setTempLoading] = useState([]);
  const closedProjects = useCloseProjects();

  return (
    <AiSection title="PROJECTS CLOSED">
      {closedProjects.length > 0 ?
        <Row className="gx-md-5">
          {
            closedProjects.map((item, key) => {
              return (
                <Col xl="4" md="6" key={key}>
                  <div className="ai-project closed">
                    <Link to={(item.contract && item.contract !== 'TBA') ? `projects/${item.contract}` : `#`} className="ai-project-header" style={{ backgroundImage: `url(${item.logo})` }}>
                      <span className="ai-project-status opening">Closed</span>
                      <span className="ai-project-symbol">{item.contract !== 'TBA' ? item.symbol : ''}</span>
                      {item.athMultiplier && <div className="ai-project-tag"><small>ATH</small>&nbsp;<b>{item.athMultiplier}x</b></div>}
                      <div className="ai-project-logo mb-3">
                        <img src={item.logo} alt={item.name} />
                      </div>
                      <div className="ai-project-name">{item.name}</div>
                    </Link>
                    <div className="ai-project-socials">
                      {
                        item.pancakeswap &&
                        <a style={{ backgroundColor: '#47d4dc' }} href={item.pancakeswap} target="_blank" rel="noopener noreferrer">
                          <img height="18" src="/images/pancake-swap.png" alt="" />
                        </a>
                      }
                      {
                        item.website &&
                        <a href={item.website} target="_blank" rel="noopener noreferrer"><i className="fas fa-globe"></i></a>
                      }
                      {
                        item.twitter &&
                        <a href={item.twitter} target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
                      }
                      {
                        item.medium &&
                        <a href={item.medium} target="_blank" rel="noopener noreferrer"><i className="fab fa-medium-m"></i></a>
                      }
                      {
                        item.telegram &&
                        <a href={item.telegram} target="_blank" rel="noopener noreferrer"><i className="fab fa-telegram"></i></a>
                      }
                    </div>
                    <div className="ai-project-description">
                      {item.description}
                    </div>
                    <div className="ai-project-detail">
                      <ul>
                        <li>
                          <span>Swap rate</span><br />
                          <b className="nowrap text-white">
                            1 {item.symbol} = {(item["rate"])} {item["projectTokenSymbol"]}
                          </b>
                        </li>
                        <li>
                          <span>Cap</span><br />
                          <b className="nowrap text-white">
                            {`${item.maxTotalParticipationAllocated || 0} ${item.symbol || ""}`}
                          </b>
                        </li>
                        <li>
                          <span>Access</span><br />
                          <b className="nowrap text-white">
                            {item.isPrivate ? "Private" : "Public"}
                          </b>
                        </li>
                        <li>
                          <div className={item.state == 'O' ? 'ai-project-progress-wrap disabled' : 'ai-project-progress-wrap'}>
                            <div className="mb-2 d-flex justify-content-between align-items-center ai-project-progress-top">
                              <div className="ai-project-col">Progress</div>
                              {item.state != 'O' && <div className="ai-project-col ai-project-col-participants text-end">Participants <b className="text-white">{item['totalCountUserParticipated']}</b></div>}
                            </div>
                            <div className='ai-project-progress'>
                              {(!tempLoading || tempLoading.length <= 0) && <div title="9" className="ai-project-progress-percent" style={{ width: `${(item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100}%` }}></div>}
                              {tempLoading && tempLoading.length > 0 && tempLoading.map((progress, idx) => {
                                return (<div key={idx} title="0" className="ai-project-progress-percent animation" style={{ width: progress.width + '%', left: progress.left + '%' }}></div>)
                              })}
                              <div className="ai-project-progress-label">
                                <span><b>{((item.totalFundParticipated / item.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                {item.state == 'O' && <span className="participants-center"><b className="">{item['totalCountUserParticipated']}</b> Participants</span>}
                                <span className="text-allocation"><b className="">{item.totalFundParticipated.toFixed(4)}/{item.maxTotalParticipationAllocated}</b></span>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>

                    {
                      item.button_name && <div className="ai-project-cta text-center">
                        <a href={item.button_url} target="blank" className="btn-pd py-3 h-auto">
                          <span>{item.button_name}</span>
                        </a>
                      </div>
                    }
                  </div>
                </Col>
              )
            })

          }
        </Row> : <div>No projects currently closed</div>
      }
    </AiSection>
  );
};

export default ClosedProjectsComponent;
