import React from "react";
import moment from 'moment';

const ScheduleInformation = (props) => {
  return (
    <div className="ai-card-info schedule">
      <div className="ai-card-info-title">Schedule</div>
      <div className="ai-card-info-inner">
        <div className="table-responsive">
          <table className="table mb-0 pp-table-info text-white">
            <tbody>
              <tr className="card-header" style={{ border: "none" }}>
                <td>Round</td>
                <td>Opens</td>
                <td>Closes</td>
              </tr>
              {props.roundInfo.length > 0 &&
                props.roundInfo.map((item, key) =>
                (
                  <tr key={key}>
                    <td>{item.round}</td>
                    <td>
                      <h5 className="mb-0">{moment(item.opens * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                    </td>
                    <td>
                      <h5 className="mb-0">{moment(item.closes * 1000 || 0).utc().format('MM-DD HH:mm')} UTC</h5>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ScheduleInformation;
