import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import $ from 'jquery';
import LeaderBoardButton from "../../../shared/components/ButtonLeader";
import { LEADER_BOARD_LINK } from "../../../_configs";

const AiBanner = () => {
    const handleScroll = (e) => {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#AiProjects").offset().top
        }, 500);
    }
    return <div className="ai-banner">
        <Container>
            <Row className="align-items-center">
                <Col lg="6" className="order-lg-1 order-2 mt-4 mt-lg-0">
                    <h1 className="glitch" data-text="WELCOME TO AIPAD">WELCOME TO AIPAD</h1>
                    <div>Welcome to #AIPad, the revolutionary #launchpad focused on #AI<br />
                        We are thrilled to have you join our community and be part of this exciting journey.</div>
                    <div className="mt-4 mt-lg-5 d-flex">
                        <a href='https://www.aipad.tech/' target="_blank" className='ai-btn ms-0 me-3' rel="noopener noreferrer">
                            <span className="decor"></span>
                            🤖Homepage
                        </a>
                        <a href={LEADER_BOARD_LINK} target="_blank" className='ai-btn ms-0 me-3' rel="noopener noreferrer">
                            <span className="decor"></span>
                            🔥Leader board
                        </a>
                        <a onClick={handleScroll} className='ai-btn ms-0 me-0 d-none d-md-flex' rel="noopener noreferrer">
                            <span className="decor"></span>
                            ⚡️Projects
                        </a>
                   
                    </div>
                </Col>
                <Col lg="6" className="text-center order-lg-2 order-1">
                    <img src="/images/hologram.png" alt="" className="img-fluid" />
                </Col>
            </Row>
        </Container>
    </div>
}
export default AiBanner