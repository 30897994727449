import exactMath from 'exact-math';
import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useActiveWeb3React } from "../../../hook";
import { useKycStatus, useLatestBlockNumber } from "../../../hook/useState";
import { getKYC } from '../../../redux/services/account.api';

import { showKYC } from "../../../_configs";
import { ACTION_CONST, ROUTES } from "../../constants";
import ConnectWallet from '../../modals/ConnectWallet';
import WalletInfo from '../../modals/WalletInfo';
import { getAmountPadToken } from '../../utils/contractHelpers';
import Web3Helper from '../../utils/walletExtensionUtils';
import AiButtonKYC from '../ButtonKYC';
import { usePadTokenBalance, useWeb3Utils } from "../hook/useSate";
import "./index.scss";

const AiHeader = () => {

  const dispatch = useDispatch();
  const walletUtils = useWeb3Utils()
  const bscPadTokenBalance = usePadTokenBalance();
  const latestBlock = useLatestBlockNumber()
  // const stakedAmount = useStakeAmount()
  const kycStatus = useKycStatus();

  const [balancePadTokenBSC, setBLPadTokenBSC] = useState(0);
  const [balancePadTokenETH, setBLPadTokenETH] = useState(0);

  const { account, library, error, chainId } = useActiveWeb3React()

  //login wallet
  useEffect(() => {
    if (error)
      return;
    if (account && library && library.provider) {
      const web3 = new Web3Helper(library.provider, account, chainId)
      dispatch({
        type: ACTION_CONST.ENABLE_WALLET_SUCCESS,
        data: web3
      })
      return;
    }
  }, [library, account, error, dispatch, chainId])
  //set balance
  useEffect(() => {
    // debugger
    if(!account){
      return ;
    }
 //get balance of in eth chain
    getAmountPadToken(1, account ).then(data => {
      setBLPadTokenETH(data)
      console.log("balance in ETH:", data)
    })

    //get balance in of binance chain
    getAmountPadToken(56, account ).then(data => {
      setBLPadTokenBSC(data)
      console.log("balance in BSC:", data)
    })


    if(!walletUtils){
      return;
    }
      //get bnb balance
      walletUtils.web3.eth.getBalance(account).then(balance => {
        dispatch({
          type: ACTION_CONST.GET_BNB_BALANCE,
          data: exactMath.div(balance, 10 ** 18)
        })
      }).catch(err => {
        console.log(err);
      })
       

  }, [latestBlock, walletUtils, account,dispatch]);

  //check show hide status
  useEffect(() => {
    if (!account) {
      return;
    }
    if ((Number(bscPadTokenBalance)) >= Number(showKYC)) {
      getKYCAddress(account);
    }

  }, [account, bscPadTokenBalance, latestBlock, dispatch])

  useEffect(()=>{
    dispatch({
      type: ACTION_CONST.GET_AIPAD_WALLET_BALANCE,
      data: Number(balancePadTokenBSC) + Number(balancePadTokenETH)
    })
  },[balancePadTokenBSC, balancePadTokenETH, dispatch])

  //get kyc
  const getKYCAddress = (address) => {

    getKYC(address, 'state').then(response => {
      address = address.toLowerCase()
      if (response) {
        const state = response.state;
        if (state === 1) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'START'
          })
        }
        if (state === 2) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'PENDING'
          })
        }
        if (state === 3) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'APPROVED'
          })
        }
        if (state === 4) {
          return dispatch({
            type: ACTION_CONST.GET_KYC_INFO,
            data: 'ERROR'
          })
        }
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleOnclickKyc = () => {
    getKYC(account, 'url').then(data => {
      if (data) {
        const url = data.url
        window.open(url, "_blank")
      }

    }).catch(err => {
      console.log(err);
    })
  }

  const handleTabChange = () => {
    $('.navbar-toggler').trigger('click');
  }

  return <Navbar className='ai-navbar' variant='dark' expand="md" fixed='top'>
    <Container>
      <Navbar.Brand href="https://aipad.tech">
        <img src="/images/logo.png" alt="AiPad" width={70} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="navbarApp" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto text-md-left text-center mb-3 mb-md-0">
          <NavLink className="nav-link" activeClassName="active" to={ROUTES.PROJECTS} onClick={handleTabChange}>Projects</NavLink>
          <Nav.Link href="https://bridge.aipad.tech/" target="_blank">Bridge</Nav.Link>
        </Nav>
        <Nav className="ms-md-auto">
          {
            !account ? <ConnectWallet /> :
              <>
                <WalletInfo />
                { Number(bscPadTokenBalance) >= Number(showKYC) &&
                  <>
                    {kycStatus === 'START' && 
                      <AiButtonKYC status="start" icon={<i className="fas fa-bolt me-1"></i>} onClick={() => handleOnclickKyc()} />}
                    {kycStatus === 'PENDING' && 
                      <AiButtonKYC status="pending" icon={<i className="fas fa-exclamation-triangle me-1"></i>} onClick={() => handleOnclickKyc()} />}
                    {kycStatus === 'APPROVED' && 
                      <AiButtonKYC status="success" icon={<i className="fas fa-check me-1"></i>} disabled />}
                    {kycStatus === 'ERROR' && 
                      <AiButtonKYC status="error" icon={<i className="fas fa-times me-1"></i>} onClick={() => handleOnclickKyc()} />}
                  </>
                }
               
              </>
          }
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>;
};

export default AiHeader;
