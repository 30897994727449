import React, { useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { connectorLocalStorageKey } from '../../components/literals';
import { connectors } from '../../constants';
import useAuth from '../../hook/useAuth';
import { LEADER_BOARD_LINK } from '../../_configs';
import AiButton from '../components/Button';
import LeaderBoardButton from '../components/ButtonLeader';

const ConnectWallet = ({ buttonSize = 'sm' }) => {
    const { login } = useAuth();

    const [showModal, setShowModal] = useState(false);

    const handleConnectClick = useCallback((walletConfig) => {
        try {
            login(walletConfig.connectorId);
            window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
            setShowModal(false);
         
        } catch (error) {
            console.log(error);
        }
    }, [login])


    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>

            <AiButton size={buttonSize} buttonText='Connect wallet' onClick={() => setShowModal(true)} />
            {/* <LeaderBoardButton url={LEADER_BOARD_LINK}/> */}
            <Modal size='sm' className='ai-modal' centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Connect to wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        connectors.filter(x => x.enable)?.map((entry, idx) => <AiButton
                            size={`md w-100 ${idx === 0 ? 'mb-3' : ''}`}
                            key={idx}
                            icon={<img className="me-2" src={entry.icon} height="26" alt={entry.connectorId} />}
                            buttonText={entry.title}
                            onClick={() => handleConnectClick(entry)}
                        />)
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ConnectWallet;


