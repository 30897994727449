import React from "react";
import "./index.scss";

const AiButton = ({ type = "button", variant = "", size = "md", buttonText = "Test", icon, onClick, disabled = false }) => {
    return <button type={type} className={`ai-btn ${size} ${variant}`} disabled={disabled} onClick={onClick}>
        <span className="decor"></span>
        {icon ? icon : <></>}
        {buttonText}
    </button>
}
export default AiButton