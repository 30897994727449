import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { ACTION_CONST, ROUTES } from "../../constants";
import { useActiveWeb3React } from "../../hook";
import { useBlockingUI, useInfoRound, useLatestBlockNumber, useSelectedProject, useShowModalHelp, useWeb3Utils } from "../../hook/useState";
import { actGetListProjects, actSelectedProject, actSetCurrentContract } from "../../redux/action/user";
import ConnectWallet from "../../shared/modals/ConnectWallet";
import { getCountDown } from "../../shared/utils/helper";
import { helpers } from "../../shared/utils/helpers";
import { history } from "../../shared/utils/history";
import PoolInformation from "./components/PoolInformation";
import ScheduleInformation from "./components/ScheduleInformation";
import YourAllocationComponent from "./components/YourAllocationComponent";
import ApproveModal from "./modals/ApproveModal";
import InvestmentModal from "./modals/InvestModal";
import './index.scss';
import TokenInformation from "./components/TokenInformation";
import { Col, Container, Row } from "react-bootstrap";

const ProjectDetailPage = () => {
  const dispatch = useDispatch();
  let params = useParams();
  const [contractAddress, setContractAddress] = useState("");
  const [tokenBalance, setTokenBalance] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [remainingAllocation, setMaxTokenAllocation] = useState(0);
  const [userParticipation, setUserParticipation] = useState(0);
  const [amountPurchased, setAmountPurchased] = useState(0);
  const [countBuy, setCountBuy] = useState(0);
  const [enableJoinProject, setEnableJoinProject] = useState(false);
  const [projectState, setProjectState] = useState(null);
  const [tier, setTier] = useState("");
  const [roundState, setRoundState] = useState(0);
  const [textRoundState, setTextRoundState] = useState('')
  const [roundTime, setRoundTime] = useState(0);
  const [allocationNumber, setAllocationNumber] = useState(0);
  const [allocationInfo, setAllocationInfo] = useState([])
  const [layoutAllocation, setLayoutAllocation] = useState(1)
  const [yourAllocationVisible, setYourAllocationVisible] = useState(false)
  const [enableApprove, setEnableApprove] = useState(false);
  const showBlockUI = useBlockingUI()
  const [walletInfo, setWalletInfo] = useState({ remainingAllocation: 0, tokenBalance: 0 })
  const { account } = useActiveWeb3React();
  const latestBlock = useLatestBlockNumber();
  const selectedProject = useSelectedProject();
  // const showModalHelp = useShowModalHelp();
  const web3Utils = useWeb3Utils();
  const roundInfo = useInfoRound();
  const [closeTime, setCloseTime] = useState(0);
  const [openTime, setOpenTime] = useState(0)
  const [fcfsOpenTime, setFcfsOpenTime] = useState(0)

  useEffect(() => {
    const { contract } = params
    if (contract && typeof contract === "string") {
      setContractAddress(contract);
      dispatch(actSelectedProject(contract));
      dispatch(actSetCurrentContract(contract));
    } else {
      history.push(ROUTES.PROJECTS);
      return;
    }
  }, [showBlockUI,dispatch, params])


  //Job interval  
  useEffect(() => {
    if (contractAddress) {
      dispatch(actSelectedProject(contractAddress))
    }
  }, [latestBlock, contractAddress, dispatch])

  useEffect(() => {
    if (selectedProject) {
      if (selectedProject.closeTime !== closeTime) {
        setCloseTime(selectedProject.closeTime);
      }
      if (selectedProject.openTime !== openTime) {
        setOpenTime(selectedProject.openTime)
      }
      if (selectedProject.fcfsOpenTime !== fcfsOpenTime) {
        setFcfsOpenTime(selectedProject.fcfsOpenTime)
      }
      setYourAllocationVisible(selectedProject.yourAllocationVisible)
      setProjectState(selectedProject)
    }
  }, [selectedProject]);

  useEffect(() => {
    if (openTime > 0) {
      getCountDown(`idOpenTime-${selectedProject["contract"]}`, openTime * 1000, (job) => {
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_OPEN,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }

  }, [openTime, dispatch, contractAddress])

  useEffect(() => {
    if (closeTime > 0) {
      getCountDown(`idTimeClose-${selectedProject["contract"]}`, closeTime * 1000, (job) => {
        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_CLOSE,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
      });
    }
  }, [closeTime, dispatch, amountPurchased])

  useEffect(() => {
    if (fcfsOpenTime > 0) {
      getCountDown(`idFcfsOpenTime-${selectedProject["contract"]}`, fcfsOpenTime * 1000, (job) => {
        //do smt when start new count down
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_FCFS_TIME,
          data: job
        })
      }, (job) => {
        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))

      });
    }
  }, [fcfsOpenTime, dispatch, amountPurchased])

  useEffect(() => {
    //round time dc chay lai khi call wallet ==> sinh ra nhieu rountime nay
    if (roundTime > 0 && selectedProject) {
      getCountDown(`idRoundTime-${selectedProject["contract"]}`, roundTime * 1000, function start(job) {
        //do smt when countdown expired
        dispatch({
          type: ACTION_CONST.SET_JOB_COUNT_DOWN_ROUND,
          data: job
        })

      }, function end(job) {

        getWalletInfo(contractAddress);
        dispatch(actSelectedProject(contractAddress))
        //do smt when countdown expired
      });
    }

  }, [roundTime, selectedProject, dispatch])


  //check enable disable approve button
  useEffect(() => {


    if (Number(remainingAllocation) > Number(allocationNumber)) {
      setEnableApprove(true)
    } else {
      setEnableApprove(false)
    }
  }, [allocationNumber, remainingAllocation, amountPurchased])


  useEffect(() => {

    if (account && web3Utils && projectState) {
      dispatch(actGetListProjects())
      // console.log("count===>", count++);

      fetchData()

    }
    async function fetchData() {
      const { contract } = params

      if (contract) {
        await getWalletInfo(contract);
        await getAllowance();
        await getYourAllocation(contract)
      }
    }

  }, [account, web3Utils, countBuy, contractAddress, projectState, latestBlock, dispatch, params]);


  // console.log("projectState==>", projectState);
  const getWalletInfo = async (contract) => {
    if (web3Utils) {
      web3Utils.getInfoWallet(contract).then(

        data => {
          // console.log("getInfoWallet==>", data);
          setWalletInfo({
            remainingAllocation: data.remainingAllocation / 10 ** projectState.decimal,
            tokenBalance: data.tokenBalance / 10 ** projectState.decimal,
            ethBalance: data.ethBalance
          })

          setEthBalance(data.ethBalance);
          setTokenBalance(data.tokenBalance / 10 ** projectState.decimal);

          setUserParticipation(data.userParticipation / 10 ** projectState.decimal);
          setMaxTokenAllocation(data.remainingAllocation / 10 ** projectState.decimal);
          setTier(data.tier);
          setRoundState(data.roundState);
          setTextRoundState(data.roundStateText)


          if (document.getElementById('idTextRoundState')) {
            document.getElementById('idTextRoundState').innerHTML = data.roundStateText;
          }



          if (roundTime != data.roundTimestamp) {
            setRoundTime(data.roundTimestamp);
          }

          if (!(data.roundState === 1 || data.roundState === 3)) {
            // console.log('call here state ')
            setEnableJoinProject(false);
            return;
          }


          if (projectState.state === "C" || projectState.state === "P" || projectState.address === 'TBA') {
            setEnableJoinProject(false)
            return;
          }
          else {
            if (selectedProject.isPrivate) {
              if (data.remainingAllocation === 0) {

                setEnableJoinProject(false)
                return;
              } else {
                setEnableJoinProject(true)
                return;
              }
            } else {
              setEnableJoinProject(true)
              setMaxTokenAllocation(projectState.maxSingleParticipationAllocated)
              // remainingAllocation = maxSingleParticipationAllocated
            }
          }

        }
      ).catch(err => {

        console.log(err);
      })
    }

  }


  const getAllowance = async () => {
    web3Utils.getAllowance(selectedProject.tokenAddress, selectedProject.contract).then(data => {
      setAllocationNumber(data)
    }).catch(err => {
      console.log(err);
    })
  }

  const getYourAllocation = (contract) => {
    web3Utils.getInfoAllocations(contract).then(data => {
      setLayoutAllocation(data.layout)
      setAllocationInfo(data.infoAllocation)
    }).catch(err => {
      console.log(err);
    })
  }

  return (
    <>
      {selectedProject ?
        <div className="ai-project-detail">
          <Container>
            <Row className="align-items-center">
              <div className="d-block d-lg-none mb-3">
                <div className="ai-project-d">
                  <div className="ai-project-d-logo">
                    <img src={selectedProject.logo} alt={selectedProject.name} />
                  </div>
                </div>
              </div>
              <Col lg="9">
                <h1 className="ai-project-d-name glitch" data-text={selectedProject.name}>{selectedProject.name}</h1>
              </Col>
              <Col lg="3">
                <div className="ai-project-d-socials justify-content-start justify-content-lg-end">
                  {
                    selectedProject.pancakeswap &&
                    (<a href={selectedProject.pancakeswap} target="_blank" rel="noopener noreferrer">
                      <img height="20" src="/images/pancake-swap.png" alt="" />
                    </a>)
                  }
                  {
                    selectedProject.website &&
                    (<a href={selectedProject.website} target="_blank" rel="noopener noreferrer">
                      <i className="fas fa-globe-americas"></i>
                    </a>)
                  }
                  {
                    selectedProject.twitter &&
                    (<a href={selectedProject.twitter} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-twitter"></i>
                    </a>)
                  }
                  {
                    selectedProject.medium &&
                    (<a href={selectedProject.medium} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-medium-m"></i>
                    </a>)
                  }
                  {
                    selectedProject.telegram &&
                    (<a href={selectedProject.telegram} target="_blank" rel="noopener noreferrer">
                      <i className="fab fa-telegram-plane"></i>
                    </a>)
                  }
                </div>
              </Col>
            </Row>
            <div className="ai-project-d-description">{selectedProject.description}</div>
            <Row className="mb-5">
              <Col lg="4" className="d-none d-lg-block">
                <div className="ai-project-d">
                  <div className="ai-project-d-logo">
                    <img src={selectedProject.logo} alt={selectedProject.name} />
                  </div>
                </div>
              </Col>
              <Col lg="8">
                <div className="ai-project-intro">
                  <Row className="gx-xl-5">
                    <Col md="5">
                      <div className="position-relative">
                        <div className="items-morex">
                          {(selectedProject.state === "O" || selectedProject.state === "F") &&
                            <>
                              <span className="pp-status-open text-success"><i className="mdi mdi-circle text-success"></i> {'Open'}</span>
                            </>
                          }
                          {selectedProject.state === "C" &&
                            <>
                              <span className="pp-status-closed text-danger"><i className="mdi mdi-circle text-danger"></i> {'Closed'}</span>
                            </>
                          }
                          {
                            selectedProject.state === "P" &&
                            <>
                              {
                                selectedProject.openTime !== undefined ?
                                  <span className="pp-status-opening text-warning" ><i className="mdi mdi-circle  text-warning"></i> {'Opens in'} <b id={`idOpenTime-${selectedProject["contract"]}`}>0d 0h 0m 0s</b></span>
                                  : <span className="pp-status-opening text-warning"><i className="mdi mdi-circle  text-warning"></i>{'TBA'}</span>
                              }
                            </>
                          }
                        </div>

                        {selectedProject.state !== "P" && <div className="mb-4">
                          {account &&
                            <div className="pp-card-info">
                              <hr class="mb-2 mt-2" />
                              <div className="pp-card-col w-100">
                                Your balance<br />
                                <div className="d-flex justify-content-between align-items-center">
                                  <strong className="text-white text-white" id="idBusdBalance">{helpers.formatNumberDownRound(tokenBalance, 4)} {selectedProject.symbol}</strong>
                                </div>
                                {selectedProject.symbol !== "BNB" &&
                                  <div className="d-flex justify-content-between align-items-center">
                                    <strong id="idKcsBalance" className="text-white text-white">{helpers.formatNumberDownRound(ethBalance, 4)} BNB</strong>
                                  </div>
                                }
                                <hr class="mb-2 mt-2" />
                                <div className="pp-card-col mb-3 mb-md-0">
                                  Your approved amount:<br />
                                  <b className="text-white text-white">{helpers.formatNumberDownRound(allocationNumber, 4)} {selectedProject.symbol}</b>
                                </div>

                              </div>
                              <div className="pp-card-col w-100 mt-3">
                                {tier && <div>
                                  Your tier<br />
                                  <b className="text-white text-white">{tier}</b>
                                </div>}
                              </div>
                            </div>
                          }
                        </div>
                        }

                        {(selectedProject.state === "P" && account) && <div className="mb-4">
                          <div className="card card-img-holder bg-gradient-danger mx-auto mx-lg-0">
                            <div className="card-body py-3 px-3 px-md-4">
                              {account &&
                                <div className="pp-card-info mt-2">
                                  <div className="pp-card-col">
                                    Your balance<br />
                                    {
                                      selectedProject.openTime !== undefined ?
                                        <div className="d-flex justify-content-between align-items-center">
                                          <h4 className="mb-0"><strong id="idBusdBalance">
                                            {helpers.formatNumberDownRound(tokenBalance, 4) || 0}&nbsp;
                                            {selectedProject.symbol}
                                          </strong></h4>
                                          {
                                            selectedProject.state !== "P" &&
                                            <h6 id="idBusdConvert">1 {selectedProject.symbol} = {selectedProject.rate} {selectedProject.projectTokenSymbol}</h6>
                                          }
                                        </div>
                                        : <div></div>
                                    }

                                    {selectedProject.symbol !== "BNB" &&
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h4 className="mb-0"><strong id="idethBalance">{helpers.formatNumberDownRound(ethBalance, 4) || 0} BNB</strong></h4>
                                      </div>
                                    }
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                        }
                      </div>
                      {!account ? <ConnectWallet buttonSize="md wd" /> : <></>}
                    </Col>

                    <Col md="7">
                      <div className="position-relative">
                        {selectedProject.state !== "P" &&
                          <div>
                            {/* If not connect show open time  else show round time.(if state = 4 ==> show closes)*/}
                            {
                              (selectedProject.state == "O") &&
                              <div className="pp-card-info">
                                {account ?
                                  roundState !== 4 ?
                                    <div className="pp-card-col w-100">
                                      {
                                        textRoundState && <div>
                                          <span id="idTextRoundState"> {textRoundState}</span>
                                          <br />
                                        </div>
                                      }
                                      <b id={`idRoundTime-${selectedProject["contract"]}`} className="text-white"> 0d 0h 0m 0s</b>
                                    </div> :
                                    <div className="items-morex">ROUND CLOSED</div>
                                  :
                                  <div className="pp-card-col w-100">First Come First Serve <u>opens</u> in:<br />
                                    <b id={`idFcfsOpenTime-${selectedProject["contract"]}`} className="text-white"> 0d 0h 0m 0s</b>
                                  </div>
                                }
                              </div>
                            }

                            {
                              selectedProject.state == "F" &&
                              <div className="pp-card-info">
                                {account ?
                                  roundState !== 4 ?
                                    <div className="pp-card-col w-100">
                                      <span id="idTextRoundState"> {textRoundState}</span>
                                      <br />
                                      <b id={`idRoundTime-${selectedProject["contract"]}`} className="text-white"> 0d 0h 0m 0s</b>
                                    </div> :
                                    <div className="items-morex">ROUND CLOSED</div>
                                  :
                                  <div className="pp-card-col w-100">Closing in:<br />
                                    <b id={`idTimeClose-${selectedProject["contract"]}`} className="text-white"> 0d 0h 0m 0s</b>
                                  </div>
                                }
                              </div>
                            }

                            {
                              selectedProject.state == "C" &&
                              <div className="pp-card-info text-white">
                                <div className="items-morex">CLOSED</div>
                              </div>
                            }

                            {
                              account &&
                              <div>
                                <hr className="mb-2 mt-2" />
                                <div className="pp-card-info">
                                  <div className="d-flex justify-content-between w-100">
                                    <div className="pp-card-col w-100">
                                      Swapped<br />
                                      <b id="idUseParticipation" className="text-white">{helpers.formatNumberDownRound(userParticipation, 4)} {selectedProject.symbol}</b>
                                      <div>
                                        <b className="text-white"> {`${helpers.formatNumberDownRound(userParticipation * selectedProject.rate, 4)} ${selectedProject.projectTokenSymbol}`}</b>
                                      </div>
                                    </div>
                                    <div className="pp-card-col w-100">
                                      Remaining Allocation<br />
                                      <b id="idBusdMaxBuy" className="text-white">{helpers.formatNumberDownRound(remainingAllocation, 4)} {selectedProject.symbol}</b>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                            <hr className="mb-2 mt-2" />
                            <div className="pp-card-info">
                              <div className="d-flex justify-content-between w-100">
                                {/* Progress bar */}
                                {selectedProject.state !== "O" ?
                                  <div className="pp-card-col w-100">
                                    Swap progress<br />
                                    <div className="ai-project-progress">
                                      <div className="ai-project-progress-percent" style={{ width: `${(selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100}%` }}></div>
                                      <div className="ai-project-progress-label">
                                        <span><b>{((selectedProject.totalFundParticipated / selectedProject.maxTotalParticipationAllocated || 0) * 100).toFixed(2)}%</b></span>
                                        <span><b>{selectedProject.totalFundParticipated.toFixed(4)}/{selectedProject.maxTotalParticipationAllocated} {selectedProject.symbol}</b></span>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <div className="pp-card-col w-100 ai-project-progress-wrap light-progress disabled">
                                    <div className="ai-project-progress text-center">
                                      <div className="ai-project-progress-percent ai-project-progress-percent-card"></div>
                                      <div className="ai-project-progress-label">
                                        <span className="participants-center" >Allocation round</span>
                                        <span className="participants-center" style={{ top: "8px" }}>{selectedProject.totalCountUserParticipated} Participants</span>
                                      </div>
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    </Col>
                  </Row>
                  {account ?
                    <div className="mt-4 pt-4" style={{ borderTop: '1px rgba(255,255,255,.1) solid' }}>
                      {selectedProject.state !== "P" &&
                        <div className="d-flex flex-wrap" style={{ gap: '15px' }}>
                          <ApproveModal
                            disabled={!enableApprove}
                            remainingAllocation={remainingAllocation}
                            walletInfo={walletInfo}
                            handleBuyClick={() => setCountBuy(countBuy + 1)}
                            handleInputAmount={(n) => setAmountPurchased(n)}
                            tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
                          />
                          <InvestmentModal
                            disabled={!enableJoinProject || allocationNumber == 0}
                            walletInfo={walletInfo}
                            allocationNumber={allocationNumber}
                            remainingAllocation={remainingAllocation} handleBuyClick={() => setCountBuy(countBuy + 1)}
                            countClick={countBuy}
                            tokenBalance={helpers.formatNumberDownRound(tokenBalance, 4)}
                            symbol={selectedProject?.symbol}
                            handleInputAmount={(n) => setAmountPurchased(n)}
                          />
                        </div>
                      }
                    </div> : <></>
                  }
                </div>
              </Col>
            </Row>

            <Row className="gx-xl-5">
              <Col lg="6">
                <PoolInformation selectedProject={selectedProject} />
              </Col>
              <Col lg="6">
                <TokenInformation selectedProject={selectedProject} />
                <ScheduleInformation roundInfo={roundInfo} />
              </Col>
            </Row>

            {yourAllocationVisible &&
              <YourAllocationComponent
                allocationInfo={allocationInfo}
                tokenSymbol={selectedProject.projectTokenSymbol}
                decimals={projectState.decimal}
                contractAddress={contractAddress}
                tokenAddress={projectState.projectTokenContract}
                handleBuyClick={() => setCountBuy(countBuy + 1)}
                claim={selectedProject.claim}
                layout={layoutAllocation}
              />
            }
          </Container>
        </div>
        :
        <div className="p-4 text-center">Loading...</div>
      }
    </>
  );
};

export default ProjectDetailPage;
