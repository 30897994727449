import React from 'react';
import { Container } from 'react-bootstrap';
import './index.scss';

const AiSection = ({ title, children }) => {
    return <div className="ai-section">
        <Container>
            <div className="ai-section-title glitch" data-text={title}>{title}</div>
            <div className="ai-section-inner">{children}</div>
        </Container>
    </div>
}
export default AiSection