export const MODE = process.env.REACT_APP_MODE || "TESTNET";

export const BSC_EXPLORER = {
    56: "https://bscscan.com",
    97: "https://testnet.bscscan.com",
};



export const STAKING_CONTRACT_ADDRESS = {
    56: "",
    97: "",
};

export const TOKEN_AIPAD_ADDRESS = {
    56: "0xE55d97A97ae6A17706ee281486E98A84095d8AAf",
    97: "0xe0d24e7695fd9dcc57a097388811fbaeece68ce7",
    1:"0xE55d97A97ae6A17706ee281486E98A84095d8AAf"

};

export const POOL_INTERVAL = 15000;

export const BACKEND_URI = 'https://yugi-oh.site'
// export const BACKEND_URI = 'https://app.aipad.tech'
export const NODE_URI = {
    56: [
        "https://bsc-dataseed.binance.org/",
        "https://bsc-dataseed1.defibit.io/",
        "https://bsc-dataseed1.ninicoin.io/",
        "https://bsc-dataseed3.binance.org/",
        "https://bsc-dataseed4.defibit.io/",
        "https://bsc-dataseed3.ninicoin.io/",
    ],
    97: ["https://data-seed-prebsc-1-s2.binance.org:8545/"],
    1: ['https://mainnet.infura.io/v3/e6d8a9c75b2e4e8c9d1e4c57446ffded'],
    42161: ['https://endpoints.omniatech.io/v1/arbitrum/one/public']
};

export const KYC_BACK_END_URI = "https://bscpad.com";


export const LEADER_BOARD_LINK = 'https://www.aipad.tech/leaderboard';
export const chainIdsSupport = [56,1];

export const defaultChainId = 56
    // process.env.REACT_APP_MODE === "MAINNET" ? 56 : 97;

export const showKYC = 100.0