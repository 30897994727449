import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import packageJson from '../../../../package.json';
import { ROUTES } from '../../constants';
import "./index.scss";

export default function AiFooter() {
  return <footer className="ai-footer">
    <Container className="container">
      <Row className='align-items-center'>
        <Col lg="5" md="2" xs="6">
          <img src='/images/logo.png' width={60} alt="AiPad" />
        </Col>
        <Col lg="2" md="2" xs="6" className='text-lg-center text-end'><span>v{packageJson.version}</span></Col>
        <Col lg="5" md="8" className="d-flex justify-content-between justify-content-md-end text-md-end text-center mt-2 mt-md-0">
          {/* <NavLink activeClassName="active" target="_blank" to={ROUTES.PRIVACY_POLICY}>Privacy Policy</NavLink>
          <span className='mx-2'>|</span>
          <NavLink target="_blank" to={ROUTES.COOKIES_POLICY}>Cookies Policy</NavLink>
          <span className='mx-2'>|</span>
          <NavLink target="_blank" to={ROUTES.TERM_OF_USES}>Terms of Use</NavLink> */}
        </Col>
      </Row>
    </Container>
  </footer>;
}