import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import useCopyToClipboard from '../components/hook/CopyToClibboard';
import { BSC_EXPLORER, showKYC } from '../../_configs';
import { ACTION_CONST } from '../constants';
import { useActiveWeb3React } from '../../hook';
import useAuth from '../../hook/useAuth';
import AiButton from '../components/Button';
import { helpers } from '../utils';
import { usePadTokenBalance } from '../components/hook/useSate';
import { Modal } from 'react-bootstrap';
import LeaderBoardButton from '../components/ButtonLeader';

const WalletInfo = () => {
    const dispatch = useDispatch();
    const bscPadTokenBalance = usePadTokenBalance();
    const [copied, setCopied] = useCopyToClipboard(1000);
    const { logout } = useAuth();
    const { account, chainId } = useActiveWeb3React();
    const [showModal, setShowModal] = useState(false);

    const handleLogout = () => {
        logout();
        localStorage.clear();
        dispatch({
            type: ACTION_CONST.LOG_OUT_WALLET_SUCCESS
        })
        window.location.reload();
    }

    const handleCloseModal = () => {
        setShowModal(false);
    }

    return (
        <>
           
            <AiButton onClick={() => setShowModal(true)} size='sm wd' buttonText={<><b>{helpers.formatTransactionHash(account, 3, 3)}</b>&nbsp;-&nbsp;{helpers.formatNumberDownRoundWithExtractMax(bscPadTokenBalance, 3)}$AIPAD</>} />  
            
            {   bscPadTokenBalance < showKYC &&
             <LeaderBoardButton  text="KYC MIN 100 AIPAD"/>
            }
            
            <Modal className='ai-modal' centered show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Your wallet</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <h5 style={{ fontSize: '19px', wordBreak: 'break-word' }} className="text-white mb-4">
                        <b>{account}</b>
                    </h5>
                    <div className='d-inline-flex'>
                        <a href={`${BSC_EXPLORER[chainId]}/address/${account}`} target="_blank" className='ai-btn sm me-3' rel="noopener noreferrer">
                            <span className="decor"></span>
                            <i className="fas fa-external-link-alt me-1"></i>
                            View on BscScan
                        </a>
                        <AiButton size='sm' onClick={() => setCopied(account)} icon={<i className={!copied ? 'far fa-clone me-1' : 'fas fa-check me-1'}></i>} buttonText="Copy Address" />
                    </div>
                    <div className="mt-5">
                        <AiButton onClick={handleLogout} buttonText="Logout" variant="warning" />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WalletInfo;


