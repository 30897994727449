import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLatestBlockNumber } from "../../hook/useState";
import { actGetListProjects } from "../../redux/action/user";
import AiBanner from "./components/Banner";
import ClosedProjectsComponent from "./components/ClosedProjectsComponent";
import OpeningProjectsComponent from "./components/OpeningProjectsComponent";
import WaitingProjectsComponent from "./components/WaitingProjectsComponent";
import './index.scss';

const ProjectsPage = () => {
    const dispatch = useDispatch();
    const latestBlock = useLatestBlockNumber();

    useEffect(() => {
        dispatch(actGetListProjects())
    }, [latestBlock, dispatch])

    return <div className="ai-projects">
        <AiBanner />
        <div className="ai-projects-list" id="AiProjects">
            <OpeningProjectsComponent />
            <WaitingProjectsComponent />
            <ClosedProjectsComponent />
        </div>
    </div>
}
export default ProjectsPage;